import './components/drawer';
import './components/drawerToggle';
import './components/cart-drawer';
import './components/remove-item';
import './components/quick-add';

import Accordion from './components/accordion';
import createOverlay from './components/page-overlay';

document.addEventListener('DOMContentLoaded', () => {
  // Set up accordions
  [...document.querySelectorAll('[data-accordion]')].forEach(
    accordion => new Accordion(accordion)
  );
  // Set Up page Pverlay
  createOverlay(document.querySelector('[data-page-overlay]'));
});
